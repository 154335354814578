


















































import { AuthForgotPasswordRequest } from '@/types/api/AuthForgotPassword';
import { ApiResponse } from '@/types/api/Response';
import Vue from 'vue';
export default Vue.extend({
  'name': 'ComponentForgotPassword',
  data () {
    return {
      'form': {
        'email': ''
      } as AuthForgotPasswordRequest,
      'responseText': ''
    };
  },
  'methods': {
    onSubmit () {
      this.responseText = '';
      this.axios.post('forgot-password', null, {
        'params': this.form
      })
        .then(({ data }) => {
          this.form.email = '';
          const response = data as ApiResponse;

          this.responseText = response.message;
        });
    }
  }
});
